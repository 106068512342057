<template>
    <div>
        <loading-component></loading-component>
        {{msg}}
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'

import { wx_login_request } from '@/network/user'

export default {
    name:'WXLoginView',
    data(){
        return {
            msg: ''
        }
    },
    computed:{},
    methods:{},
    filters:{},
    props:{},
    created(){
        this.$store.commit('true_loading')
        wx_login_request({ code: this.$route.query.code})
                .then((s) => {
                    if (s.status === 0) {
                        this.$router.replace({ path: '/' })
                    } else {
                        this.msg = s.msg
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent
    },
    watch:{}
}
</script>

<style>
</style>